import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import axios from 'axios'

import Layout from '../components/layout'

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',
      loading: false,
      success: false,
      failure: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.sleeper = this.sleeper.bind(this)
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    const { name, email, phone, message } = this.state

    this.setState({ loading: true })

    axios
      .post(this.props.data.wordpressPage.acf.contact_page.contact_form_url, {
        name,
        email,
        phone,
        message,
      })
      .then(this.sleeper(500))
      .then(result => {
        if (result.status >= 200 && result.status <= 300) {
          this.setState({ loading: false })
          this.setState({ success: true })
        }
      })
      .catch(() => {
        this.setState({ loading: false })
        this.setState({ failure: true })
      })
  }

  sleeper = ms => {
    return function(x) {
      return new Promise(resolve => setTimeout(() => resolve(x), ms))
    }
  }

  render() {
    const { data, location } = this.props
    const { wordpressPage, wordpressAcfOptions } = data
    const { acf } = wordpressPage
    const { contact_page, seo, open_graph, twitter } = acf
    const { options } = wordpressAcfOptions

    const { loading, success, failure } = this.state

    return (
      <Layout location={location.pathname}>
        <Helmet
          title={seo.title}
          meta={[
            { name: 'description', content: seo.description },
            { name: 'og:title', content: open_graph.title },
            { name: 'og:description', content: open_graph.description },
            { name: 'og:image', content: open_graph.image.source_url },
            { name: 'og:url', content: 'https://www.modinfinity.com/contact' },
            { name: 'twitter:title', content: twitter.title },
            { name: 'twitter:description', content: twitter.description },
            { name: 'twitter:image', content: twitter.image.source_url },
            { name: 'twitter:card', content: twitter.card_size },
          ]}
        />
        <section className="container mx-auto pt-8 lg:pt-16 px-3">
          <div className="md:w-3/4 lg:w-3/5 xl:w-1/2 md:mx-auto">
            <h1 className="md:pr-32 lg:pr-0 xxl:pr-3 font-black text-4xl md:text-5xl lg:text-6xl xxl:text-7xl text-indigo-darkest text-center md:text-left">
              {contact_page.title}
            </h1>
            {contact_page.copy && (
              <p className="mt-6 lg:mt-20 text-xl text-indigo-darkest font-light leading-normal">
                {contact_page.copy}
              </p>
            )}
          </div>
          <div className="md:w-3/4 lg:w-3/5 xl:w-1/2 md:mx-auto mt-8 lg:mt-16">
            <h2 className="text-3xl lg:text-5xl xxl:text-6xl text-indigo-darkest font-black text-center md:text-left">
              {contact_page.contact_info_title}
            </h2>
            <div className="mt-6 lg:mt-12">
              <p className="text-xl text-indigo-darkest font-light leading-normal">
                <span className="font-bold">Email: </span>
                <a
                  className="no-underline text-indigo-darkest border-b-4 border-transparent hover:border-indigo-base"
                  href="mailto:{options.email}"
                >
                  {options.email}
                </a>
              </p>
              <p className="mt-2 xl:mt-4 text-xl text-indigo-darkest font-light leading-normal">
                <span className="font-bold">Phone: </span>{' '}
                <a
                  className="no-underline text-indigo-darkest border-b-4 border-transparent hover:border-indigo-base"
                  href="tel:{options.phone}"
                >
                  {options.phone}
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="container mx-auto pt-16 relative">
          {(loading || success || failure) && (
            <div className="absolute inset-0 flex items-center justify-center bg-white">
              {loading && (
                <div className="flex flex-col items-center justify-center">
                  <div className="w-16 h-16">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="icon-send w-full h-full"
                    >
                      <path
                        className="icon-primary"
                        d="M12 20.1L3.4 21.9a1 1 0 0 1-1.3-1.36l9-18a1 1 0 0 1 1.8 0l9 18a1 1 0 0 1-1.3 1.36L12 20.1z"
                      />
                      <path
                        className="icon-secondary"
                        d="M12 2c.36 0 .71.18.9.55l9 18a1 1 0 0 1-1.3 1.36L12 20.1V2z"
                      />
                    </svg>
                  </div>
                  <p className="mt-6 text-xl text-indigo-darkest font-light leading-normal">
                    Sending...
                  </p>
                </div>
              )}
              {success && (
                <div className="flex flex-col items-center justify-center">
                  <div className="w-16 h-16">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="icon-mood-happy w-full h-full"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        className="icon-secondary"
                      />
                      <path
                        className="icon-primary"
                        d="M6.8 14h10.4a1 1 0 0 1 .86 1.5 7 7 0 0 1-12.12 0A1 1 0 0 1 6.8 14zm1.7-3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                      />
                    </svg>
                  </div>
                  <p className="mt-6 text-xl text-indigo-darkest font-light leading-normal">
                    Successfully sent!
                  </p>
                </div>
              )}
              {failure && (
                <div className="flex flex-col items-center justify-center">
                  <div className="w-16 h-16">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="icon-mood-sad w-full h-full"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        className="icon-secondary"
                      />
                      <path
                        className="icon-primary"
                        d="M16.47 18H7.53a1 1 0 0 1-.75-1.67 6.98 6.98 0 0 1 10.44 0 1 1 0 0 1-.75 1.67zM8.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                      />
                    </svg>
                  </div>
                  <p className="mt-6 text-xl text-indigo-darkest font-light leading-normal">
                    Failed to send.
                  </p>
                </div>
              )}
            </div>
          )}
          {contact_page.show_contact_form === 'yes' ? (
            <div className="md:w-3/4 lg:w-3/5 xl:w-1/2 md:mx-auto px-3">
              <form onSubmit={this.handleSubmit}>
                <div className="flex flex-col">
                  <label
                    className="pl-2 font-black text-indigo-darkest uppercase text-xs"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    className="mt-1 px-2 py-3 border-b-4 border-indigo-lighter focus:border-indigo-base"
                    type="text"
                    name="name"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
                <div className="mt-6 flex flex-col">
                  <label
                    className="pl-2 font-black text-indigo-darkest uppercase text-xs"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="mt-2 px-2 py-3 border-b-4 border-indigo-lighter focus:border-indigo-base"
                    type="email"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
                <div className="mt-6 flex flex-col">
                  <label
                    className="pl-2 font-black text-indigo-darkest uppercase text-xs"
                    htmlFor="phone"
                  >
                    Phone
                  </label>
                  <input
                    className="mt-1 px-2 py-3 border-b-4 border-indigo-lighter focus:border-indigo-base"
                    type="tel"
                    name="phone"
                    id="phone"
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="mt-6 flex flex-col">
                  <label
                    className="pl-2 font-black text-indigo-darkest uppercase text-xs"
                    htmlFor="message"
                  >
                    How can I help?
                  </label>
                  <textarea
                    className="mt-1 px-2 py-3 border-b-4 border-indigo-lighter focus:border-indigo-base resize-none"
                    name="message"
                    id="message"
                    rows="3"
                    value={this.state.message}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
                <div className="my-6 md:my-12 flex flex-col md:flex-row">
                  <button
                    className="px-6 py-3 border-2 border-indigo-darkest bg-indigo-lightest hover:bg-indigo-darkest focus:bg-indigo-darkest transition-bg font-bold uppercase text-indigo-darkest hover:text-indigo-lightest focus:text-indigo-lightest"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div />
          )}
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "contact" }) {
      acf {
        contact_page {
          title
          copy
          contact_info_title
          contact_form_url
          show_contact_form
        }
        seo {
          title
          description
        }
        open_graph {
          title
          description
          image {
            source_url
          }
        }
        twitter {
          title
          description
          card_size
          image {
            source_url
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        email
        phone
      }
    }
  }
`
